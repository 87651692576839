import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const Badge = (props) => {
  const { category, className } = props

  const getTextColor = (color) => {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    color = color.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    var rgb = [ parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16) ];

    // http://www.w3.org/TR/AERT#color-contrast
    const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                        (parseInt(rgb[1]) * 587) +
                        (parseInt(rgb[2]) * 114)) / 1000);
    return (brightness > 125) ? 'black' : 'white';
  }

  return (
    <div className={`d-flex w-auto ws-badge ${className}`} style={{ backgroundColor: category.categoryColor }}>
      <FontAwesomeIcon className="m-0 ml-2 my-auto" icon={category.categoryLogo} style={{ color: getTextColor(category.categoryColor ?? "#ffffff") }}/>
      <p className="ml-2 mr-2 my-0" style={{ color: getTextColor(category.categoryColor ?? "#ffffff") }}>{category.categoryName}</p>
    </div>
  )
}

export { Badge }

export default Badge