import React, { useState } from "react"
import { connect } from "react-redux"
import { Container, Row, Col, Card, Button } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { convertSecToHMS } from "../../helpers"

import { request } from "../../api"

import { Layout, Table, Badge } from "../../components"
import CategoryModal from "./CategoryModal"
import NewPostModal from "./NewPostModal"

const Categories = () => {
  const [state, setState] = useState({
    loading: false,
    data: null,
    error: null
  });

  const [modal, setModal] = useState(false);

  const toggleModal = (refresh=false) => {
    setModal(!modal);

    if (refresh) refreshData();
  }

  const refreshData = () => {
    setState({ ...state, loading: true })

    request("/blog/category", null, "GET", true)
    .then(res => {
      setState({ ...state, loading: false, data: res.data })
    })
    .catch(err => setState({ ...state, loading: false, error: err }))
  };

  const addCategory = (e) => {
    e.preventDefault();

    toggleModal();
  }

  if(!state.loading && !state.data && !state.error) {
    refreshData();
  }

  return (
    <>
      <CategoryModal modal={modal} toggle={toggleModal} currentlyEdited={null} />
      <div className="w-100 d-flex mb-2">
        <h3 className="w-auto ml-0 mr-auto mt-auto mb-0">Categories</h3>
        <Button
            size="md"
            className="btn-simple btn-success ml-auto mr-0"
            onClick={e => addCategory(e)}
          ><FontAwesomeIcon icon="add" />{" Add"}</Button>
      </div>
      {!state.loading && state.data ?
        <Table
          data={state.data}
          headers={[ '#', 'Badge', 'Is Hidden?' ]}
          rowRender={(data, i) => (
            <tr>
              <th scope="row">{i+1}</th>
              <td className="d-flex"><Badge category={data} className="ml-0 mr-auto" /></td>
              <td><FontAwesomeIcon icon={data.isHidden ? "check" : "x"} /></td>
            </tr>
          )}
          hidePaging={true}
          total={state.data.length}
        />
      :
        <h3 className="w-100">Loading...</h3>
      }
    </>
  )
}

const AllPosts = () => {
  const [state, setState] = useState({
    loading: false,
    data: null,
    error: null,
    catError: null,
    loadingData: false,
    categories: null
  });

  const [modal, setModal] = useState(false);

  const toggleModal = (refresh=false) => {
    setModal(!modal);

    if (refresh) refreshData();
  }

  const refreshData = () => {
    setState({ ...state, loading: true })

    request("/blog/post", null, "GET", true)
    .then(res => {
      setState({ ...state, loading: false, data: res.data });
    })
    .catch(err => setState({ ...state, loading: false, error: err }))
  }

  const addPost = (e) => {
    e.preventDefault();

    toggleModal();
  }

  if(!state.loading && !state.data && !state.error) {
    refreshData();
  }

  if (!state.loadingData && !state.categories && !state.catError) {
    request("/blog/category", null, "GET", true)
    .then(res => {
      setState({ ...state, loadingData: false, categories: res.data })
    })
    .catch(err => setState({ ...state, loadingData: false, catError: err }));
  }

  console.log(state)

  return (
    <>
      <NewPostModal modal={modal} toggle={toggleModal} currentlyEdited={null} categories={state.categories ?? []} />
      <div className="w-100 d-flex mb-2">
        <h3 className="w-auto ml-0 mr-auto mt-auto mb-0">All Posts</h3>
        <Button
            size="md"
            className="btn-simple btn-success ml-auto mr-0"
            onClick={e => addPost(e)}
          ><FontAwesomeIcon icon="add" />{" Add"}</Button>
      </div>
      {!state.loading && state.data ?
        <Table
          data={state.data}
          headers={[ '#', 'Title', '' ]}
          rowRender={(data, i) => (
            <tr>
              <th scope="row">{i+1}</th>
              <td>{data.title}</td>
              <td className="d-flex">
                <Button color="warning" href={`/edit-post/${data.postId}`} tag="a" className="ml-auto mr-0">
                  <FontAwesomeIcon icon="pencil" />
                </Button>
              </td>
            </tr>
          )}
          hidePaging={true}
          total={state.data.length}
        />
      :
        <h3 className="w-100">Loading...</h3>
      }
    </>
  )
}

const DashboardPage = props => {
  return (
    <Layout>
      <Container>
        <Row className="d-flex mt-5 mb-3">
          <Col md="8" className="mx-auto">
            <Categories />
          </Col>
        </Row>
        <Row className="d-flex mb-3">
          <Col md="8" className="mx-auto">
            <AllPosts />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn
})

const Dashboard = connect(mapStateToProps)(DashboardPage)

export { Dashboard }

export default Dashboard