import React, { useState, useEffect } from "react"
import { Container, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from "reactstrap"
import { SketchPicker } from 'react-color';

import { request } from "../../api"
import { Badge } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CategoryModal = ({ modal, toggle, currentlyEdited, ...args }) => {
  const defaultFields = {
    categoryName: "",
    isHidden: false,
    categoryColor: "#fff",
    categoryLogo: ""
  }
  
  const [fields, setFields] = useState(defaultFields);

  useEffect(() => {
    if (currentlyEdited == null)
      setFields(defaultFields);
    else
      setFields({
        categoryName: currentlyEdited.categoryName,
        isHidden: currentlyEdited.isHidden,
        categoryColor: currentlyEdited.categoryColor,
        categoryLogo: currentlyEdited.categoryLogo
      });
  }, [currentlyEdited]);

  const updateField = (key, value) => {
    var newFields = { ...fields };

    newFields[key] = value;

    setFields(newFields)
  }

  const submitUpdates = e => {
    e.preventDefault();

    var id = currentlyEdited == null ? undefined : currentlyEdited.categoryId;

    request(`/blog/category`, { ...fields, categoryId: id }, currentlyEdited == null ? "POST" : "PATCH", true, 'application/json')
    .then(() => {
      setFields(defaultFields);
      toggle(true);
    })
    .catch(err => console.log(err));
  }

  var handleChangeComplete = (color) => {
    updateField('categoryColor', color.hex);
  }

  return (
    <Modal isOpen={modal} toggle={toggle} {...args}>
    <ModalHeader toggle={toggle}>Add Category</ModalHeader>
    <ModalBody>
      <div className="d-flex mb-2">
        <p className="w-auto ml-0 mr-auto my-auto">Preview:</p>
        <Badge className="ml-auto mr-0" category={fields} />
      </div>
      <Form>
        <FormGroup>
          <Label for="categoryName">
            Link Name
          </Label>
          <Input
            id="categoryName"
            name="categoryName"
            value={fields.linkName}
            onChange={e => updateField("categoryName", e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <div className="d-flex">
            <Label for="categoryLogo">
              Category Logo (Optional)
            </Label>
            <FontAwesomeIcon icon={fields.categoryLogo} className="ml-auto mr-0 my-auto" />
          </div>
          <Input
            id="categoryLogo"
            name="categoryLogo"
            value={fields.categoryLogo}
            onChange={e => updateField("categoryLogo", e.target.value)}
          />
        </FormGroup>
        <FormGroup check inline>
          <Input 
            type="checkbox"
            onChange={e => updateField("isHidden", !fields.isHidden)}
            id="isHiddenCheckbox"
          />
          <Label check>
            Is Hidden?
          </Label>
        </FormGroup>
        <div className="mt-2 d-flex flex-column">
          <div className="d-flex flex-row mb-2">
            <label className="ml-0 mr-auto mb-auto">Category Color</label>
            <div className="w-25 ml-auto mr-0" style={{ backgroundColor: fields.categoryColor, border: 'black solid 2px' }}></div>
          </div>
          <SketchPicker width="85%" className="mx-auto" color={fields.categoryColor} onChangeComplete={handleChangeComplete} />
        </div>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={submitUpdates}>
        Save
      </Button>{' '}
      <Button color="danger" onClick={toggle}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
  );
};

export default CategoryModal;