import constants from './constants'
import { request } from '../../api'
import { updateTokens, removeTokens } from '../../api/cookies'
import { history } from '../../helpers/history'

function findElem(arr, key, value) {
  for(let i = 0; i < arr.length; i++)
    if (arr[i][key] == value) return arr[i]
}

export function loginUser(data) {
  return async (dispatch) => {
    dispatch({ type: constants.LOGIN_REQUEST })

    request("/user/login", data, "POST", false)
      .then(res => {
        updateTokens(res.data);

        localStorage.setItem("user", JSON.stringify(res.data.user));

        dispatch({ type: constants.LOGIN_SUCCESS, payload: res.data.user });

        request("/blog/author", null, "GET", true)
          .then(authRes => {
            localStorage.setItem("author", JSON.stringify(authRes.data));

            history.push("/dashboard");

            dispatch({ type: constants.AUTHOR_SUCCESS, payload: authRes.data });
          })
          .catch(authErr => {
            const { status, data } = authErr.response;

            if (status == 400 && data)
            {
              history.push("/setup-author");
            }
            else
            {
              dispatch({ type: constants.AUTHOR_ERROR, payload: authErr });

              dispatch(logoutUser("/login", "An unknown error has occurred!"));
            }
          });
      })
      .catch(err => {
        dispatch({ type: constants.LOGIN_ERROR, payload: err });
      })
  }
}

export function setupAuthor(data) {
  return async (dispatch) => {
    dispatch({ type: constants.AUTHOR_REQUEST });

    request("/blog/author", data, "POST", true)
      .then(res => {
        localStorage.setItem("author", JSON.stringify(res.data));

        history.push("/dashboard");

        dispatch({ type: constants.AUTHOR_SUCCESS, payload: res.data });
      })
      .catch(err => {
        dispatch({ type: constants.AUTHOR_ERROR, payload: err });

        dispatch(logoutUser("/login", "An unknown error has occurred!"));
      });
  }
}

export function logoutUser(route="/", errMessage="") {
  return dispatch => {
    dispatch({ type: constants.LOGOUT_REQUEST });

    localStorage.removeItem("user");
    localStorage.removeItem("author");

    if (errMessage != "") console.log(`Logout reason: "${errMessage}"`)

    removeTokens();
    history.push(route);
    dispatch({ type: constants.LOGOUT_SUCCESS });
  }
}