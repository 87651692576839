import React, { useState } from "react"
import { connect } from "react-redux"

import { setupAuthor } from "../../redux/auth/actions"

import { Container, Row, Col, Card, Input, InputGroup, Button } from "reactstrap"
import Layout from "../../components/Layout"
import { history } from "../../helpers/history"

const SetupAuthorComp = props => {
  const [state, setState] = useState({
    name: "",
    showPassword: false
  })

  const submitForm = event => {
    event.preventDefault()

    const { dispatch } = props, { name } = state

    console.log(props)

    if(name)
      dispatch(setupAuthor({ name }))
    else
      console.log("One of the forms are not filled out!")
  }

  const { loggedIn, error, hasAuthor, user } = props

  if(!loggedIn)
    history.push('/login')

  if(hasAuthor)
    history.push('/dashboard')

  return (
    <Layout>
      <Container className="mt-5">
        <Row className="d-flex">
          <Col md="8" className="ml-auto mr-auto">
            <Card>
              <Container>
                <Row className="d-flex">
                  <Col md="9" className="ml-auto mr-auto">
                    <h2 className="mt-2 mb-2 text-center">Login</h2>
                    <hr />
                    <p className="mb-3">User: {user.username}</p>
                    <label className="mb-1">Name</label>
                    <Input
                      placeholder="Enter your name..."
                      onChange={event => setState({ ...state, name: event.target.value })}
                      className="mb-3"
                    />
                    <div className="w-100 d-flex">
                      <Button
                        className="ml-auto mr-0 mb-3"
                        onClick={event => submitForm(event)}
                      >
                        Submit
                      </Button>
                    </div>
                    {error &&
                      <div className="w-100 d-flex">
                        {error && error.response && error.response.data && error.response.data.message && <p className="w-auto ml-auto mr-0">{error.response.data.message}</p>}
                      </div>
                    }
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  hasAuthor: state.auth.hasAuthor,
  user: state.auth.user,
  error: state.auth.error
})

export const SetupAuthor = connect(mapStateToProps)(SetupAuthorComp)

export default SetupAuthor