import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Container, Row, Col, Card, Button, Modal, ModalHeader, ModalBody, Form, Input, FormGroup, Label, ModalFooter } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import MDEditor from '@uiw/react-md-editor';

import { request } from "../../api"
import { history } from "../../helpers/history"

import { Layout, Table, Badge } from "../../components"

const UpdatePostDetailsModal = ({ modal, toggle, currentlyEdited, ...args }) => {
  const defaultFields = {
    title: "",
    description: ""
  }
  
  const [fields, setFields] = useState(defaultFields);

  useEffect(() => {
    if (currentlyEdited == null)
      setFields(defaultFields);
    else
      setFields({
        title: currentlyEdited.title,
        description: currentlyEdited.description
      });
  }, [currentlyEdited]);

  const updateField = (key, value) => {
    var newFields = { ...fields };

    newFields[key] = value;

    setFields(newFields)
  }

  const submitUpdates = e => {
    e.preventDefault();

    var id = currentlyEdited.postId;

    request(`/blog/post/${id}`, { ...fields }, "PUT", true, 'application/json')
    .then(() => {
      setFields(defaultFields);
      toggle(true);
    })
    .catch(err => console.log(err));
  }

  return (
    <Modal isOpen={modal} toggle={toggle} {...args}>
      <ModalHeader toggle={toggle}>Add Category</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="title">
              Title
            </Label>
            <Input
              id="title"
              name="title"
              value={fields.title}
              type="textarea"
              onChange={e => updateField("title", e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">
              Description
            </Label>
            <Input
              id="description"
              name="description"
              value={fields.description}
              type="textarea"
              rows="4"
              onChange={e => updateField("description", e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={submitUpdates}>
          Save
        </Button>{' '}
        <Button color="danger" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const EditPostPage = props => {
  const { id } = props.match.params

  if(!id)
    history.push('/404')

  const [state, setState] = useState({
    loading: false,
    data: null,
    body: null,
    error: null,
    catError: null,
    loadingData: false,
    categories: null
  });

  const [modal, setModal] = useState(false);

  const toggleModal = (refresh=false) => {
    setModal(!modal);

    if (refresh) refreshData();
  }

  const refreshData = () => {
    setState({ ...state, loading: true })

    request(`/blog/post/${id}`, null, "GET", true)
    .then(res => {
      setState({ ...state, loading: false, data: res.data, body: res.data.content });
    })
    .catch(err => setState({ ...state, loading: false, error: err }))
  }

  if(!state.loading && !state.data && !state.error) {
    refreshData();
  }

  if (!state.loadingData && !state.categories && !state.catError) {
    request("/blog/category", null, "GET", true)
    .then(res => {
      setState({ ...state, loadingData: false, categories: res.data })
    })
    .catch(err => setState({ ...state, loadingData: false, catError: err }));
  }

  const updateBody = update => {
    setState({ ...state, body: update });
  }

  const savePostContent = () => {
    request(`/blog/post/${id}`, { content: state.body }, "POST", true)
    .then(() => {
      refreshData();
    })
    .catch(err => setState({ ...state, loading: false, error: err }))
  }

  return (
    <Layout>
      <Container className="mt-4">
        {state.data
          ?
            <>
              <UpdatePostDetailsModal modal={modal} toggle={toggleModal} currentlyEdited={state.data} categories={state.categories ?? []} />
              <h1 className="w-100" style={{ fontSize: '3.65rem' }}>{state.data.title}</h1>
              <p style={{ fontSize: '1.2rem' }} className="font-italic mb-1">{state.data.description}</p>
              <div className="d-flex flex-row">
                {state.data.categories.map(c => (
                  <Badge className="w-auto mr-2" category={c} />
                ))}
              </div>
              <div className="w-100 d-flex" style={{ borderBottom: '1px solid gray', paddingBottom: '0.5rem' }}>
                <p className="mb-0 w-auto ml-0 mr-auto mt-auto">{state.data.author.name}</p>
                <Button color="warning" size="sm" onClick={() => toggleModal()}>
                  <FontAwesomeIcon icon="pencil" />
                </Button>
              </div>
              <MDEditor
                data-color-mode="light"
                value={state.body}
                onChange={updateBody}
                className="mb-3 mt-3"
                highlightEnable={false}
                height="750px"
              />
              <div className="d-flex w-100">
                <Button color="primary" disabled={(state.data.content ?? '') == state.body} onClick={() => savePostContent()} className="ml-auto mr-0">
                  <FontAwesomeIcon icon="save" />{" Save"}
                </Button>
              </div>
            </>
          :
            <h2 className="mt-4 text-center">Loading...</h2>
        }
      </Container>
    </Layout>
  )
}

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn
})

const EditPost = connect(mapStateToProps)(EditPostPage)

export { EditPost }

export default EditPost