import React, { useState, useEffect } from "react"
import { Container, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import { SketchPicker } from 'react-color';

import { request } from "../../api"
import { Badge } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewPostModal = ({ modal, toggle, currentlyEdited, categories=[], ...args }) => {
  const defaultFields = {
    title: "",
    description: "",
    categories: []
  }
  
  const [fields, setFields] = useState(defaultFields);

  useEffect(() => {
    if (currentlyEdited == null)
      setFields(defaultFields);
    else
      setFields({
        title: currentlyEdited.title,
        description: currentlyEdited.description,
        categories: currentlyEdited.categories
      });
  }, [currentlyEdited]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const updateField = (key, value) => {
    var newFields = { ...fields };

    newFields[key] = value;

    setFields(newFields)
  }

  const submitUpdates = e => {
    e.preventDefault();

    request(`/blog/post`, { ...fields }, "POST", true, 'application/json')
    .then(() => {
      setFields(defaultFields);
      toggle(true);
    })
    .catch(err => console.log(err));
  }

  const addCategory = idx => {
    fields.categories.push(idx);
  }

  const removeCategory = idx => {
    updateField("categories", fields.categories.filter(i => i != idx));
  }

  return (
    <Modal isOpen={modal} toggle={toggle} {...args} size="lg">
    <ModalHeader toggle={toggle}>Add New Post</ModalHeader>
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="title">
            Title
          </Label>
          <Input
            id="title"
            name="title"
            value={fields.title}
            type="textarea"
            onChange={e => updateField("title", e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="description">
            Description
          </Label>
          <Input
            id="description"
            name="description"
            value={fields.description}
            type="textarea"
            rows="4"
            onChange={e => updateField("description", e.target.value)}
          />
        </FormGroup>
      </Form>
      <div className="d-flex flex-column">
        <div className="d-flex flex-row">
          <label className="ml-0 mr-auto mt-auto mb-0">Categories</label>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle caret>Add Category</DropdownToggle>
            <DropdownMenu>
              {categories.filter(c => fields.categories.includes(c.categoryId)).length == 0
                && <DropdownItem disabled>No categories!</DropdownItem>}
              {categories.filter(c => !fields.categories.includes(c.categoryId)).map(c => (
                <DropdownItem onClick={() => addCategory(c.categoryId)}><Badge category={c} /></DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="d-flex flex-row mt-2">
          {fields.categories.length == 0 &&
            <p className="w-100 text-center mb-0">No categories selected!</p>}
          {categories.filter(c => fields.categories.includes(c.categoryId)).map(c => (
            <div className="d-flex w-auto ml-2">
              <Badge category={c} className="mr-2" />
              <Button size="sm" color="danger" onClick={() => removeCategory(c.categoryId)}>
                <FontAwesomeIcon icon="trash" />
              </Button>
            </div>
          ))}
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={submitUpdates}>
        Save
      </Button>{' '}
      <Button color="danger" onClick={toggle}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
  );
};

export default NewPostModal;