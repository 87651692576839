export default {
  LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
  LOGIN_ERROR: "AUTH_LOGIN_ERROR",
  LOGOUT_REQUEST: "AUTH_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "AUTH_LOGOUT_SUCCESS",
  LOGOUT_ERROR: "AUTH_LOGOUT_ERROR",
  AUTHOR_REQUEST: "AUTH_AUTHOR_REQUEST",
  AUTHOR_SUCCESS: "AUTH_AUTHOR_SUCCESS",
  AUTHOR_ERROR: "AUTH_AUTHOR_ERROR",
  UPDATE_PROFILE_REQUEST: "AUTH_UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "AUTH_UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "AUTH_UPDATE_PROFILE_ERROR"
}